<template>
  <a-drawer
    :title="model.id > 0?'编辑推送':'添加推送'"
    :maskClosable="true"
    width="800"
    placement="right"
    :closable="true"
    @close="hide()"
    :visible="isShow"
  >
    <a-form :form="form">
      <a-form-item label="标题" v-bind="formItemLayout">
        <a-input
          v-decorator="['title',{rules: [{ required: true, message: '标题不能为空'}]}]"
        />
      </a-form-item>

      <a-form-item label="跳转链接" v-bind="formItemLayout">
        <a-input v-decorator="['link']" />
      </a-form-item>

      <a-form-item label="内容" v-bind="formItemLayout">
        <RichTextField v-model="model.content"></RichTextField>
      </a-form-item>
    </a-form>

    <div class="drawer-bootom-button">
      <a-button style="margin-right: .8rem" @click="hide">取消</a-button>
      <a-button @click="handleSubmit" type="primary" :loading="loading">提交</a-button>
    </div>
  </a-drawer>
</template>

<script>


const formItemLayout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 18 }
}
export default {
  name: 'PushEdit',
  data () {
    return {
      isShow: false,
      model: { content: '' },
      loading: false,
      formItemLayout,
      form: this.$form.createForm(this),
    }
  },
  methods: {
    show (model = {}) {
      this.isShow = true
      this.model = JSON.parse(JSON.stringify(model))
      if (this.model.id === undefined) {
        this.model.content = ''
      } else {
        let fields = ['title', 'link']
        Object.keys(this.model).forEach((key) => {
          if (fields.indexOf(key) !== -1) {
            this.form.getFieldDecorator(key)
            let obj = {}
            obj[key] = this.model[key]
            this.form.setFieldsValue(obj)
          }
        })
      }
    },
    hide () {
      this.form.resetFields()
      this.isShow = false
    },

    handleSubmit () {
      this.form.validateFields((err, values) => {
        if (!err) {
          let change = this.form.getFieldsValue()
          this.model.title = change.title
          this.model.link = change.link
          if (this.model.id > 0) {
            this.loading = true
            this.$put('push', {
              ...this.model
            })
              .then(r => {
                this.loading = false
                this.$emit('success')
                this.hide()
              })
              .catch(err => {
                console.log(err)

                this.loading = false
              })
          } else {
            this.loading = true

            this.$post('push', {
              ...this.model
            })
              .then(r => {
                this.loading = false
                this.$emit('success')
                this.hide()
              })
              .catch(err => {
                console.log(err)

                this.loading = false
              })
          }
        }
      })
    },
  },

}
</script>

<style>
.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

.push-image {
  width: 200px;
}
</style>
