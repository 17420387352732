<template>
  <a-drawer
    title="定时发送"
    :maskClosable="true"
    width="600"
    placement="right"
    :closable="false"
     @close="hide()"
    :visible="isShow"
  >
    <a-form :form="form">
      <a-form-item label="时间设置：" v-bind="formItemLayout">
        <a-date-picker class="ps-form-item-date" :disabled-date="disabledDate" v-model="date">
          <span slot="suffixIcon"></span>
        </a-date-picker>
        <a-time-picker class="ps-form-item-time" format="HH:mm" v-model="time">
          <span slot="suffixIcon"></span>
        </a-time-picker>
      </a-form-item>
      <a-form-item label="发送消息：" class="ps-form-msg flex">
        <a-checkbox-group class="d-ib" :value="msgType" @change="onChangeMsgType">
          <a-checkbox v-for="v in msgList" :style="checkboxStyle" :value="v.id" :key="v.id">
           {{v.title}}
          </a-checkbox>
        </a-checkbox-group>
      </a-form-item>

    </a-form>

    <div class="drawer-bootom-button">
      <a-button style="margin-right: .8rem" @click="hide">取消</a-button>
      <a-button @click="handleSubmit" type="primary" :loading="loading">提交</a-button>
    </div>
  </a-drawer>
</template>

<script>
import moment from 'moment'

const formItemLayout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 18 }
}
export default {
  name: 'PushEdit',
  data () {
    return {
      isShow: false,
      model: { content: '' },
      loading: false,
      formItemLayout,
      form: this.$form.createForm(this),
      time: '',
      date: '',
      msgType: [],
      checkboxStyle: {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
        marginLeft: 0
      },
      msgList: []
    }
  },
  methods: {
    show (model = {}) {
      this.isShow = true
      this.msgType.length > 0 ? this.msgType.shift() : ''
      this.time ? this.time = '' : ''
      this.date ? this.date = '' : ''
      this.$get('push',{pageNum: 1, pageSize: 65535}).then(r=> {
        let {rows} = r.data
        console.log(rows);
        this.msgList = [...rows]
      })
    },
    hide () {
      this.form.resetFields()
      this.isShow = false
    },
    onChangeMsgType(e) {
      console.log(e)
      // let {value} = e.target
      if(this.msgType.length == 1 && e.indexOf(this.msgType[0]) == -1) {

        this.msgType.shift()
        return
      }
      this.$set(this.msgType, 0, e[e.length - 1])
      console.log(this.msgType);
    },
    disabledDate(cur) {
      return cur && cur < moment().startOf('day')
    },

    handleSubmit () {

      if(this.date && this.time && this.msgType.length==1) {
        let date = this.date.format('YYYY-MM-DD') + ' ' + this.time.format('HH:mm')
        date = new Date(date).getTime()
        console.log(date)
        if(date < new Date().getTime()) {
          this.$message.warning('设置的时间小于当前时间，无法发送')
          return
        }
        this.loading = true
        this.$post('push/timing', {
          sendTime: date,
          pushId: this.msgType[0],
        }).then(r => {
          console.log(r)
          this.$message.success('定时发送设置成功！')
          this.loading = false
          this.hide()
        })
        return
      }
      this.$message.warning('请将内容填写完整')
    }
  }
}
</script>

<style lang="less">
.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

.push-image {
  width: 200px;
}
.ps-form {

  &-item-time {
    display: block;
    margin-top: 16px;
    width: 323px;
    .ant-time-picker-input {
      text-align: center;
    }
  }
  &-item-date {
  width: 323px;
    .ant-calendar-picker-input {
      text-align: center;
    }
  }

  &-msg .ant-form-item-label label {
    display: inline-block;
    vertical-align: top;
    height: 30px;
    line-height: 30px;
  }
}
</style>
