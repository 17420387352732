<template>
  <a-card :bordered="false" class="card-area">

    <common-table
      ref="table"
      path="push"
      :columns="columns"
      search-placeholder="搜索推送标题">

      <template slot="search">
        <a-button ghost type="primary" @click="add">新增</a-button>
        <a-button type="default" @click="send">定时发送</a-button>
      </template>

      <template slot="logo" slot-scope="{record}">
        <img :src="record.logo" class="push-logo" />
      </template>

      <template slot="operation" slot-scope="{record}">
        <action-push @click="pushMessage(record)"></action-push>
        <action-edit @click="edit(record)"></action-edit>
        <action-delete @click="remove(record)"></action-delete>
      </template>

    </common-table>

    <push-edit ref="pushEdit" @success="handleSuccess"></push-edit>
    <push-send ref="pushSend" @success="handleSuccess"></push-send>
  </a-card>
</template>
<script>
import PushEdit from './PushEdit'
import PushSend from './PushSend'
export default {
  name: 'push',
  components: {
    PushEdit,
    PushSend,
  },
  data () {
    return {

    }
  },

  computed: {
    columns () {
      let { sortedInfo } = this
      sortedInfo = sortedInfo || {}
      return [
        {
          title: '标题',
          dataIndex: 'title'
        },
        {
          title: '创建时间',
          dataIndex: 'createdAt',
          sorter: true,
        },
        {
          title: '发送人',
          dataIndex: 'author'
        },
        {
          title: '发送时间',
          dataIndex: 'pushDate',
          sorter: true,
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width:160,
          scopedSlots: { customRender: 'operation' }
        }
      ]
    }
  },
  methods: {
    add () {
      this.$refs.pushEdit.show()
    },
    send() {
      this.$refs.pushSend.show()
    },

    handleSuccess () {
      this.getList()
    },

    edit (record) {
      this.$refs.pushEdit.show(record)
    },
    remove (record) {
      var that = this
      this.$confirm({
        title: '是否删除推送?',
        content: '该操作不可撤销',
        centered: true,
        onOk () {
          that.$delete('push/' + record.id).then(() => {
            that.$message.success('删除成功')
            that.getList()
          })
        }
      })
    },

    getList () {
      this.$refs.table.getData()
    },
    pushMessage (record) {
      let that = this
      this.$confirm({
        title: '是否推送消息给所有用户?',
        content: '该操作不可撤销',
        centered: true,
        onOk () {

          const hide = that.$message.loading('正在推送消息', 0);

          that.$post('push/push/'+record.id, {
            identityIds: []
          }).then(() => {
            hide()
            that.$message.success('发送成功！')
            that.getList()
          }).catch(err=>{
            hide()
          })
        }
      })

    }
  }
}
</script>
<style lang="less" scoped>
.push-logo {
  width: 60px;
  height: 60px;
}
</style>
